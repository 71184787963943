import React from "react";
import {
  SEO,
  PageLayout,
  Content,
  OutboundLink,
  Image,
  Background,
  PaginatedGrid,
  componentIterator,
} from "@bluefin/components";
import { Grid, Header, Dropdown } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class OurStoryPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"about-page"}>
          <Grid
            stackable={true}
            doubling={true}
            columns={2}
            className={"about-grid"}
          >
            <Grid.Column className={"content-column"}>
              <Content className={"about-content"} textAlign={"left"}>
                <Header as={"h1"} className={"about-us-header"}>
                  OUR STORY
                </Header>
                <Content.Markup
                  width={16}
                  isMarkdown={false}
                  content={
                    <p>
                      Antonio adopted his family's signature recipes and started
                      creating his own pizzas as a young man in the bustling
                      city of Brooklyn. Throughout the years, he's poured his
                      heart and soul into his many business ventures, opening
                      his very first business at the age of 17 - always striving
                      to keep the family traditions alive. Today, you can
                      experience Antonio's House of Pizza famous Grandma Style
                      Pizza for yourself, with three locations across Florida:
                      Kissimmee, Orlando and now Apopka! When you step inside,
                      you'll immediately feel at home, with a comforting
                      atmosphere and friendly service that will make you want to
                      stay awhile. And of course, the pizza itself is nothing
                      short of amazing, with quality recipes that have been
                      perfected over 29 years of experience using only the
                      freshest ingredients! We welcome you to taste the
                      traditions and enjoy a meal with the ones you love most!
                    </p>
                  }
                />
                <Dropdown
                  button={true}
                  text={"Order Online"}
                  className={"order-online-dropdown-cta"}
                >
                  <Dropdown.Menu primary={true} size={"large"}>
                    <Dropdown.Item>
                      <OutboundLink
                        to={
                          "https://www.toasttab.com/antonios-house-of-pizza-kirkman-4626-s-kirkman-rd"
                        }
                        label={"Orlando"}
                      />
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <OutboundLink
                        to={
                          "https://www.toasttab.com/antonios-house-of-pizza-kissimmee-2362-east-irlo-bronson-memorial-highway-ste-a103"
                        }
                        label={"Kissimmee"}
                      />
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <OutboundLink
                        to={
                          "https://www.toasttab.com/antonios-house-of-pizza-apopka-1097-w-orange-blossom-trail"
                        }
                        label={"Apopka"}
                      />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Content>
            </Grid.Column>
            <Grid.Column className={"image-column"}>
              <Image
                className={"image"}
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <div className={"image-gallery-container"}>
            <Background
              columns={1}
              stackable={true}
              className={"images-background"}
            >
              <Background.Pattern type={"CIRCLE"}>
                <PaginatedGrid
                  rows={2}
                  columns={4}
                  className={"image-gallery-grid"}
                  mobile={{ columns: 2, rows: 4, stackable: false }}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                    }),
                    component: <Image background={true} className={"image"} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Background.Pattern>
            </Background>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
